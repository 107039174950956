// Import jQuery (if you are using jQuery via npm)
import $ from "jquery";

// Initialize WOW.js
import { WOW } from "wowjs";
import "scrollit";
import "bxslider/dist/jquery.bxslider";
import "bootstrap"
// import "@fortawesome/fontawesome-free/js/all"

// Styles
// import "wowjs/css/libs/animate.css"
import './../scss/app.scss'

const wow = new WOW();
wow.init();

$(document).ready(function () {
  // Video Popup Handling
  $("#video-icon").on("click", function (e) {
    e.preventDefault();
    $(".video-popup").css("display", "flex");
    $(".iframe-src").slideDown();
  });

  // Event delegation for the video popup to handle closing
  $(".video-popup").on("click", function (e) {
    const $target = e.target.nodeName;
    const $iframe = $(this).find("iframe");
    const videoSrc = $iframe.attr("src");

    if ($target !== "IFRAME") {
      $(".video-popup").fadeOut();
      $(".iframe-src").slideUp();
      $iframe.attr("src", ""); // Stop the video by clearing the src
      $iframe.attr("src", videoSrc); // Restore the src for the next playback
    }
  });

  // Initialize the slider with options
  $(".slider").bxSlider({
    pager: false,
  });
});

// Reusable function to toggle navbar styles on scroll
function handleNavbarScroll() {
  const bodyScroll = $(window).scrollTop();
  const $navbar = $(".navbar");
  const logoImg = bodyScroll > 50 ? "assets/images/logo-black.png" : "assets/images/logo.png";

  $(".navbar-logo img").attr("src", logoImg);
  bodyScroll > 50
    ? $navbar.addClass("nav-scroll")
    : $navbar.removeClass("nav-scroll");
}

// Attach scroll event to handle navbar style change
$(window).on("scroll", handleNavbarScroll);

// Trigger navbar styling on page load
$(window).on("load", function () {
  handleNavbarScroll(); // Handle navbar on page load

  // Initialize scrollIt.js with custom settings
  $.scrollIt({
    easing: "swing", // The easing function for the animation
    scrollTime: 900, // Duration of the animation (ms)
    activeClass: "active", // Class applied to the active nav element
    onPageChange: null, // Callback function for page change
    topOffset: -63, // Offset from the top for scrolling
  });
});
